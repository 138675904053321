@font-face {
  font-family: "ProductSans";
  src: url("./fonts/Product Sans Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ProductSans";
  src: url("./fonts/Product Sans Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "ProductSans";
  src: url("./fonts/Product Sans Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "ProductSans";
  src: url("./fonts/Product Sans Bold Italic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-Regular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;   
}

@font-face {
  font-family: "Barlow";
  src: url("./fonts/BarlowCondensed-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input,select{
  font-family: Inter;
}
html {
  font-size:16px;
  background-color: #161626;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dragged{
  opacity: 0.5;
}


.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

html{
  font-family: Inter;
}


.backgroundPatternDark {
background-color: #161626;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 1000'%3E%3Cg %3E%3Ccircle fill='%23161626' cx='50' cy='0' r='50'/%3E%3Cg fill='%23181728' %3E%3Ccircle cx='0' cy='50' r='50'/%3E%3Ccircle cx='100' cy='50' r='50'/%3E%3C/g%3E%3Ccircle fill='%23191829' cx='50' cy='100' r='50'/%3E%3Cg fill='%231b192b' %3E%3Ccircle cx='0' cy='150' r='50'/%3E%3Ccircle cx='100' cy='150' r='50'/%3E%3C/g%3E%3Ccircle fill='%231d1b2c' cx='50' cy='200' r='50'/%3E%3Cg fill='%231f1c2e' %3E%3Ccircle cx='0' cy='250' r='50'/%3E%3Ccircle cx='100' cy='250' r='50'/%3E%3C/g%3E%3Ccircle fill='%23211d30' cx='50' cy='300' r='50'/%3E%3Cg fill='%23231e31' %3E%3Ccircle cx='0' cy='350' r='50'/%3E%3Ccircle cx='100' cy='350' r='50'/%3E%3C/g%3E%3Ccircle fill='%23241f33' cx='50' cy='400' r='50'/%3E%3Cg fill='%23262034' %3E%3Ccircle cx='0' cy='450' r='50'/%3E%3Ccircle cx='100' cy='450' r='50'/%3E%3C/g%3E%3Ccircle fill='%23282136' cx='50' cy='500' r='50'/%3E%3Cg fill='%232a2337' %3E%3Ccircle cx='0' cy='550' r='50'/%3E%3Ccircle cx='100' cy='550' r='50'/%3E%3C/g%3E%3Ccircle fill='%232c2439' cx='50' cy='600' r='50'/%3E%3Cg fill='%232e253b' %3E%3Ccircle cx='0' cy='650' r='50'/%3E%3Ccircle cx='100' cy='650' r='50'/%3E%3C/g%3E%3Ccircle fill='%2330263c' cx='50' cy='700' r='50'/%3E%3Cg fill='%2332273e' %3E%3Ccircle cx='0' cy='750' r='50'/%3E%3Ccircle cx='100' cy='750' r='50'/%3E%3C/g%3E%3Ccircle fill='%2334283f' cx='50' cy='800' r='50'/%3E%3Cg fill='%23372a41' %3E%3Ccircle cx='0' cy='850' r='50'/%3E%3Ccircle cx='100' cy='850' r='50'/%3E%3C/g%3E%3Ccircle fill='%23392b42' cx='50' cy='900' r='50'/%3E%3Cg fill='%233b2c44' %3E%3Ccircle cx='0' cy='950' r='50'/%3E%3Ccircle cx='100' cy='950' r='50'/%3E%3C/g%3E%3Ccircle fill='%233D2D45' cx='50' cy='1000' r='50'/%3E%3C/g%3E%3C/svg%3E");
background-attachment: fixed;
background-size: contain;
}


.backgroundPatternLight {
background-color: #D6E5FE;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 1000'%3E%3Cg %3E%3Ccircle fill='%23D6E5FE' cx='50' cy='0' r='50'/%3E%3Cg fill='%23d4e2fc' %3E%3Ccircle cx='0' cy='50' r='50'/%3E%3Ccircle cx='100' cy='50' r='50'/%3E%3C/g%3E%3Ccircle fill='%23d2dffa' cx='50' cy='100' r='50'/%3E%3Cg fill='%23d0dbf7' %3E%3Ccircle cx='0' cy='150' r='50'/%3E%3Ccircle cx='100' cy='150' r='50'/%3E%3C/g%3E%3Ccircle fill='%23cfd8f5' cx='50' cy='200' r='50'/%3E%3Cg fill='%23cdd5f3' %3E%3Ccircle cx='0' cy='250' r='50'/%3E%3Ccircle cx='100' cy='250' r='50'/%3E%3C/g%3E%3Ccircle fill='%23ccd2f0' cx='50' cy='300' r='50'/%3E%3Cg fill='%23cacfee' %3E%3Ccircle cx='0' cy='350' r='50'/%3E%3Ccircle cx='100' cy='350' r='50'/%3E%3C/g%3E%3Ccircle fill='%23c9cbeb' cx='50' cy='400' r='50'/%3E%3Cg fill='%23c8c8e8' %3E%3Ccircle cx='0' cy='450' r='50'/%3E%3Ccircle cx='100' cy='450' r='50'/%3E%3C/g%3E%3Ccircle fill='%23c6c5e6' cx='50' cy='500' r='50'/%3E%3Cg fill='%23c5c2e3' %3E%3Ccircle cx='0' cy='550' r='50'/%3E%3Ccircle cx='100' cy='550' r='50'/%3E%3C/g%3E%3Ccircle fill='%23c4bee0' cx='50' cy='600' r='50'/%3E%3Cg fill='%23c3bbdd' %3E%3Ccircle cx='0' cy='650' r='50'/%3E%3Ccircle cx='100' cy='650' r='50'/%3E%3C/g%3E%3Ccircle fill='%23c2b8da' cx='50' cy='700' r='50'/%3E%3Cg fill='%23c1b4d7' %3E%3Ccircle cx='0' cy='750' r='50'/%3E%3Ccircle cx='100' cy='750' r='50'/%3E%3C/g%3E%3Ccircle fill='%23c0b1d4' cx='50' cy='800' r='50'/%3E%3Cg fill='%23bfaed1' %3E%3Ccircle cx='0' cy='850' r='50'/%3E%3Ccircle cx='100' cy='850' r='50'/%3E%3C/g%3E%3Ccircle fill='%23bfabce' cx='50' cy='900' r='50'/%3E%3Cg fill='%23bea7ca' %3E%3Ccircle cx='0' cy='950' r='50'/%3E%3Ccircle cx='100' cy='950' r='50'/%3E%3C/g%3E%3Ccircle fill='%23BDA4C7' cx='50' cy='1000' r='50'/%3E%3C/g%3E%3C/svg%3E");
background-attachment: fixed;
background-size: contain;
}

/*https://www.svgbackgrounds.com/*/

.lds-ripple,.lds-ripple-bright {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.lds-ripple div {
  position: absolute; 
  border: 2px solid #dadafa;
  opacity: 1;
  border-radius: 50%;
  width: 72%;
  height: 72%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple-bright div {
  position: absolute;
  border: 2px solid #222262;
  opacity: 1;
  border-radius: 50%;
  width: 72%;
  height: 72%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple-bright div:nth-child(2) {
  animation-delay: -0.5s;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  4.9% {
    opacity: 0;
    transform: scale(0);
  }
  5% {
    opacity: 1;
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}


html{
  /*display: none;*/
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0.5rem;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0.3);
  background-color: #ffffff10;
}
::-webkit-scrollbar-thumb {
  background-color: #9999a9;
  outline: none;
  border-radius: 1rem;
}
::-webkit-scrollbar-corner {background: rgba(0,0,0,0.5)}